import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

const useStyles = makeStyles(() => ({
    backdrop: {
        zIndex: 111125,
        color: '#fff',
    }
}));

const ColorCircularProgress = withStyles({
    root: {
      color: '#0F467A',
    },
})(CircularProgress);

const LazyLoader = () =>{
    const classes = useStyles();
    return(
        <Backdrop className={classes.backdrop} open={true}>
            <ColorCircularProgress />
        </Backdrop>
    )
}

export default LazyLoader;