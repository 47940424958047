import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import common_ar from "./assets/translations/ar/common.json";
import common_en from "./assets/translations/en/common.json";

// get user lang
var locals = JSON.parse(localStorage.getItem('persist:root'))
let userLang = '';
if(locals) {
  userLang = JSON.parse(locals.lang)
}
// react-next language setup
i18next.init({
  interpolation: { escapeValue: false, }, // React already does escaping
  lng: userLang || 'en', // language to use
  resources: {
      en: {
          common: common_en // 'common' is our custom namespace
      },
      ar: {
          common: common_ar
      },
  },
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement); // createRoot is part of React 18

root.render(<I18nextProvider i18n={i18next}><App /></I18nextProvider>);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register(swConfig);