import React, { Suspense, lazy } from 'react';
import { store,persistor }from './store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import LazyLoader from './LazyLoader';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material/styles'

const generateClassName = createGenerateClassName({
  seed: 'selynk',
});

const LoadableComponent = lazy(() => import('./config/Navigation'));

function App() {
  const theme = createTheme();
  return (
    <StyledEngineProvider injectFirst generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Suspense fallback={<LazyLoader />}>
                <LoadableComponent />
            </Suspense>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;